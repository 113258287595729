<template>
  <div>
    <AsupBreacrump
      v-if="asup && !isLoading"
      :asup-id="asup.id"
      :asset-id="assetId"
      :agent-id="agentId"
      :agent-version="agentVersion"
      :system-type="1012"
      @trigger-delta-build="triggerBuild(false)"
      @trigger-full-build="triggerBuild(true)"
    />

    <b-overlay
      no-center
      :show="hideDetails && isLoading === false"
      z-index="9999"
      opacity="0.95"
    >
      <template #overlay>
        <div class="text-center mt-5">
          <h3>Contract expired.</h3>
          <p>Please contact the mentIQ sales team to get more information.</p>
          <b-button
            ref="cancel"
            variant="outline-secondary"
            size="sm"
            @click="$router.go(-1)"
          >
            Back
          </b-button>
        </div>
      </template>

      <div
        ref="stickyNav"
        class="sticky-nav"
      >

        <b-card no-body>
          <b-card-header
            header-tag="nav"
            header-class="asup-detail-nav"
          >
            <p
              v-if="asup"
              class="text-muted asupShortIdentifier"
            >
              {{ asup.hostname }} / {{ asup.version }}
            </p>
            <b-nav
              card-header
              tabs
            >
              <b-nav-item
                :to="{ name: 'ppdm-detail.general' }"
                exact-active-class="active"
              >
                General
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'ppdm-detail.config' }"
                exact-active-class="active"
              >
                Configuration
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'ppdm-detail.health-issues' }"
                exact-active-class="active"
              >
                Health Issues
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'ppdm-detail.alert' }"
                exact-active-class="active"
              >
                Alerts
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'ppdm-detail.client-matrix' }"
                exact-active-class="active"
              >
                Client Matrix
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'ppdm-detail.job-duration' }"
                exact-active-class="active"
              >
                Job Duration
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'ppdm-detail.kpi' }"
                exact-active-class="active"
              >
                KPI
              </b-nav-item>

              <b-nav-item
                v-if="asset && $can('Core.Assets')"
                :to="{ name: 'ppdm-detail.contract' }"
                exact-active-class="active"
              >
                Contract &amp; SLA
              </b-nav-item>

              <b-nav-item
                v-if="asset && isCurrentUserHost"
                :to="{ name: 'ppdm-detail.internal' }"
                exact-active-class="active"
              >
                <span slot="default">
                  Internals
                  <b-badge
                    v-if="asset && asset.internalNote"
                    pill
                    variant="light-info"
                  >!</b-badge>
                </span>
              </b-nav-item>

              <b-nav-item
                v-if="$can('AsupManagement.RawAsupSystem')"
                :to="{ name: 'ppdm-detail.raw' }"
                exact-active-class="active"
              >
                Raw data
              </b-nav-item>

              <b-nav-item
                v-if="$can('Core.Incidents')"
                :to="{ name: 'ppdm-detail.tickets' }"
                exact-active-class="active"
              >
                Tickets
              </b-nav-item>

              <b-nav-item
                v-if="$can('Core.SystemCheckups') && asset && asset.hasProActiveService === true"
                :to="{ name: 'ppdm-detail.checkups' }"
                exact-active-class="active"
              >
                ProActive Checkups
              </b-nav-item>
            </b-nav>
          </b-card-header>
        </b-card>
      </div>

      <router-view
        v-if="!isLoading"
        :asup="asup"
        :asset="asset"
      />
    </b-overlay>

    <b-overlay
      :show="isLoading"
      no-wrap
      fixed
      z-index="999"
    />

  </div>
</template>

<script>

import {
  BNav, BNavItem, BBadge, BOverlay, BCard, BCardHeader, BButton,
} from 'bootstrap-vue'
import AsupDetailMixin from '@/mixins/AsupDetailMixin'

import PpdmService from '@/service/ppdm.service'
import AsupBreacrump from '@/views/asup/AsupBreadcrump.vue'

export default {
  components: {
    BNav,
    BNavItem,
    BBadge,
    BOverlay,
    BCard,
    BCardHeader,
    BButton,
    AsupBreacrump,
  },
  mixins: [AsupDetailMixin],
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      PpdmService.getAsync(this.$route.params.id, { disableTenantFilter: true })
        .then(result => {
          this.asup = result
          this.loadAsset()
        })
    },
    triggerBuild(_force) {
      const force = _force || false

      PpdmService.triggerBuildAsync(this.$route.params.id, { force })
        .then(() => {
          this.$toast.success('Build triggered. This can take a while. Please refresh the page after a few seconds.', {
            icon: true,
          })
        })
    },
  },
}

</script>

<style>
</style>
